import React, { useEffect, useContext, useCallback } from "react"
import Layout from "../components/Layout"
import "../styles/global.css"
import SEO from "../components/seo"
import { Section } from "../components/PageComponents/Section"
import styled from "@emotion/styled"
import Icon from "../components/Icon/Icon"
import { useTheme } from "emotion-theming"
import { verifyEmail } from "../Context/Actions"
import { QuoteContext } from "../Context/QuoteContext"
import Loader from "../components/GlobalAssets/Loader"
import { navigate } from "gatsby"
import { isNullOrWhitespace } from "../utils/HelperFunctions"

const VerifyPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const stableDispatch = useCallback(dispatch, [])
  const params = new URLSearchParams(location.search)
  const token = params.get("t")

  useEffect(() => {
    if (isNullOrWhitespace(token)) {
      navigate("/")
      return
    }
    stableDispatch(verifyEmail(token))
  }, [token, stableDispatch])

  if (!state.verifyEmail.hasFetched || token.length === 0) {
    return (
      <>
        <SEO title="Verifiera din epost" noIndex={true} />
        <Loader />
      </>
    )
  }

  return (
    <Layout>
      <SEO title="evoli - Verifiera din epost" noIndex={true} />
      <Section bgColor="#ffffff" fullViewHeight={true}>
        {state.verifyEmail.verified && (
          <VerifyEmailBlock
            header="Din e-post är nu verifierad"
            paragraph="Tack för att du verifierade din e-post."
            icon="email"
          />
        )}

        {!state.verifyEmail.verified && (
          <>
            <VerifyEmailBlock
              header="Något gick fel"
              paragraph="Det kan bero på att du redan verifierat din e-post eller att vi har tekniska problem för tillfället.<br /><br />Om problemet kvarstår om en stund, ta kontakt med vår support."
              icon="stop"
            />
            <OutlinedButton
              type="button"
              onClick={() => navigate("https://support.evoli.se")}
            >
              Gå till support
            </OutlinedButton>
          </>
        )}
      </Section>
    </Layout>
  )
}
export default VerifyPage

const VerifyEmailBlock = ({ header, paragraph, message, icon }) => {
  const theme = useTheme()

  return (
    <ContentContainer>
      <Icon
        name={icon}
        bgColor={theme.colors.CardContainer.iconBackground}
        iconColor={theme.colors.CardContainer.iconColor}
        iconSize="25"
      />
      <ContentHeader>{header}</ContentHeader>
      <ContentParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
    </ContentContainer>
  )
}

const OutlinedButton = styled.button`
  border: 1px solid #01cc86;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  color: #01cc86;
  font-size: 12px;
  min-width: 150px;
  margin-top: 30px;
  cursor: pointer;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 290px;
`
const ContentHeader = styled.h1`
  margin: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ff4984;
`
const ContentParagraph = styled.p`
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #70669b;
  margin-bottom: 0;

  @media (max-width: 375px) {
    font-size: 13px;
    max-width: ${props => props.customWidth || "240px"};
  }
`
